<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Claim your profile</v-toolbar-title>
          </v-toolbar>
          <v-window v-model="window">
            <v-window-item :key="0">
              <v-card-title>
                Let's start by finding your profile
              </v-card-title>
              <v-card-text>
                Enter your name or email address below
                <player-lookup @player-selected="onPlayerSelected" :clearOnSelect="true" ref="search" :disabled="loading"></player-lookup>
              </v-card-text>
            </v-window-item>
            <v-window-item :key="1">
              <v-card-title v-if="selectedPlayer">
                Your Volleyball Life Player number is {{selectedPlayer.id}}
              </v-card-title>
              <v-card-text v-if="loading">
                <div class="text-center">
                    <v-progress-circular indeterminate color="color3" v-if="loading"></v-progress-circular>
                </div>
              </v-card-text>
              <v-card-text v-if="memberships && memberships.length">
                <div class="text-subtitle-1">Good News!</div>
                You don't need to go through the claim process.
                All you have to do is log in using {{membershipIds | formatArrayOr}} as your username, <b>instead of your email address</b>.
                <br><br>
                If you have forgotten your password, you can use the forgot password button on the sign in screen. Be sure to use one of the mebership Ids listed above as your username (not your email address)
              </v-card-text>
              <v-card-text>
                <div>Go to your profile here</div>
                <v-btn
                  color="color3" class="ml-n4"
                  text
                  v-if="selectedPlayer"
                  :to="`/player/${selectedPlayer.id}?claim=true`"
                >
                <v-icon class="mr-3">fas fa-chevron-double-right</v-icon>
                https://volleyballlife.com/player/{{selectedPlayer.id}}</v-btn>
                <div>To get help on {{memberships && memberships.length ? 'editing' : 'claiming'}} your profile check out the videos below.</div>
              </v-card-text>
            </v-window-item>
          </v-window>
          <v-card-actions>
            <v-fab-transition>
              <v-btn fab v-if="window > 0" @click.stop="window=0">
                <v-icon>fas fa-caret-left</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-actions>
          <v-expand-transition>
            <v-card-text v-if="videoId">
              <youtube
                :videoId="videoId"
                player-width="100%"
                :player-vars="{autoplay: 1}"
              ></youtube>
            </v-card-text>
          </v-expand-transition>
          <v-card-text>
            <v-list>
              <v-list-item @click="showVideo('kxe7JGCAeLQ')">
                <v-list-item-icon><v-icon color="red">fab fa-youtube</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Show me how to claim my profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="showVideo('5CE01hA4KME')">
                <v-list-item-icon><v-icon color="red">fab fa-youtube</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>What if my profile says I'm Blocked?</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="showVideo('kBxB70oCPtI')">
                <v-list-item-icon><v-icon color="red">fab fa-youtube</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>How do I edit my profile?</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="showVideo('WqvfaXLPea8')">
                <v-list-item-icon><v-icon color="red">fab fa-youtube</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Are college coaches and potential partners finding you?</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlayerLookup from '@/components/Player/Search/PlayerLookup'

export default {
  data () {
    return {
      quickEdit: false,
      loading: false,
      selectedPlayer: null,
      memberships: null,
      window: null,
      videoId: null
    }
  },
  computed: {
    membershipIds () {
      return this.memberships.map(m => m.memberId)
    }
  },
  methods: {
    showVideo (id) {
      this.videoId = id
    },
    onPlayerSelected (player) {
      this.selectedPlayer = player
      this.window = 1
      this.videoId = null
      this.getMemberships(player.id)
    },
    getMemberships (id) {
      this.loading = true
      this.$VBL.player.getMemberships(id)
        .then(r => {
          this.memberships = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    PlayerLookup
  }
}
</script>
