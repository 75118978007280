<template>
    <v-autocomplete
      label="Player Lookup"
      v-model="selectedResult"
      :hint="searchHint"
      placeholder="Start typing to search"
      persistent-hint
      color="color3"
      append-icon="fas fa-search"
      autocomplete="off"
      :items="items"
      item-value="id"
      item-text="fullName"
      return-object
      :search-input.sync="term"
      :loading="searching"
      :filter="filter"
      solo-inverted
      autofocus
      clearable
      type="search"
      ref="input"
      :disabled="disabled"
      :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
      :hide-no-data="allowAdd && !searched"
    >
    <template slot="prepend-item" v-if="searching">
        <v-list-item>
          <v-list-item-content>
            <v-progress-circular indeterminate color="color3"></v-progress-circular>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template slot="no-data" v-if="allowAdd">
        <v-list-item @click="$emit('manual-click', term)">
          <v-list-item-content>
            <v-list-item-title>
              Can't find the player?
            </v-list-item-title>
            <v-list-item-subtitle>Click here to manually enter the player</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template slot="item" slot-scope="data">
        <search-result-item
          :player="data.item"
          :showId="user && user.vbl"
          :noEmit="true"
        ></search-result-item>
      </template>
      <template slot="append-item" v-if="allowAdd && searched && items.length && term && term.length >= 3">
        <v-list-item @click="$emit('manual-click', term)">
          <v-list-item-content>
            <v-list-item-title>
              Can't find the player?
            </v-list-item-title>
            <v-list-item-subtitle>Click here to manually enter the player</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import SearchResultItem from './SearchResultItem'

export default {
  props: ['sbid', 'partners', 'registration', 'clearOnSelect', 'aau', 'disabled', 'allowAdd', 'membersOnly', 'tId', 'asOf'],
  data () {
    return {
      selectedResult: null,
      searchResults: [],
      term: null,
      searching: false,
      dupDialog: false,
      prevTerm: null,
      searched: false,
      newResults: true,
      uPPs: []
    }
  },
  computed: {
    ...mapGetters(['liveUpdates', 'user']),
    searchHint () {
      return this.sbid ? `Enter an ${this.sbid === 'AVPA' ? 'AVP America' : this.sbid} number, name, or email` : 'Enter a name or email'
    },
    items () {
      return this.term && this.term.length >= 3 ? this.searchResults : this.partners ? this.partnerList : this.uPPs
    },
    partnerList () {
      return this.partners && this.partners.length ? [{ header: 'Past Partners' }, ...this.partners] : []
    },
    userId () {
      return this.user ? this.user.id : 0
    }
  },
  methods: {
    search: debounce(function () {
      this.doSearch()
    }, 333),
    doSearch () {
      if (!this.term || typeof this.term !== 'string' || this.term.length < 3 || (this.selectedResult && this.selectedResult.name === this.term)) return
      this.searching = true
      this.searched = false
      const dto = {
        term: this.term,
        allowTbd: this.allowTbd,
        membersOnly: this.membersOnly,
        tournamentId: this.registration ? this.registration.tournamentId : this.tId || 0,
        playerId: 0,
        asOf: this.asOf
      }
      this.$VBL.player.lookup(dto)
        .then(r => {
          this.searchResults = r.data || []
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
          console.log('ZZZZZZZZZZZZZZ')
          this.searched = true
        })
    },
    filter () {
      return true
    },
    focus () {
      this.$refs.input.$el.focus()
    },
    blur () {
      this.$refs.input.$el.blur()
    },
    preLoadUPPs () {
      const u = this.user
      if (u && u.profiles && u.profiles.length) {
        this.searching = true
        this.$VBL.user.getMyPlayers(this.asOf)
          .then(r => { this.uPPs = r.data })
          .catch(e => console.log(e.response))
          .finally(f => { this.searching = false })
      } else {
        this.uPPs = []
      }
    }
  },
  watch: {
    term: 'search',
    selectedResult: function (val) {
      if (val) {
        this.$emit('player-selected', val)
        if (this.clearOnSelect) {
          this.term = null
          this.$nextTick(() => {
            this.selectedResult = null
          })
        }
      }
    },
    searching: function (val) {
      this.$emit('searching-change', val)
    },
    searched: function (val) {
      this.$emit('searched-change', val)
    },
    userId: 'preLoadUPPs'
  },
  mounted () {
    this.preLoadUPPs()
  },
  components: {
    SearchResultItem
  }
}
</script>

<style>

</style>
